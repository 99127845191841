<template>
  <header>
    <img class="highouimg" src="../assets/header-bgimg.png">
  </header>
</template>

<script>
export default {

}
</script>

<style>

</style>