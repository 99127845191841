<template>
  <footer>
    <img class="gglogo" src="../assets/MessingMedialogo.png">
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>