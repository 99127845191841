import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import * as Vue from 'vue' // in Vue 3
import axios from 'axios';
import { createApp } from 'vue';
import VueAxios from 'vue-axios';
import App from './App.vue';

library.add(fas);
//  import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

//  Vue.component('font-awesome-icon', FontAwesomeIcon)
//  Vue.component('font-awesome-layers', FontAwesomeLayers)
//  Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

const app = createApp(App);
app
  .use(VueAxios, axios)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
  