<template>
  <Header/>
  <Dashboard/>
  <Footer/>
</template>

<script>
import Header from './components/Header.vue'
import Dashboard from './components/Dashboard.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Dashboard,
    Footer,
  }
}
</script>

<style>
body {
  background-color: rgb(14, 14, 14);
  margin: 0;
}

template {
  margin: 0;
}

* {
  box-sizing: border-box;
}

header {
  width: 100%;
  padding: 0px;
  margin-top: -60px;
  text-align: center;
  overflow: hidden;
}

img.highouimg {
  width: 100%; 
}

.flipcardbox {
  max-width: 1400px;
  vertical-align: center;
  margin: auto;
}

.flipcard {
  display: inline-block;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.card {
  width: 320px;
  height: 400px;
  margin: 15px;
  perspective: 1500px;
}

.card .content {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
}

.more {
  display: none;
}

.more:checked ~ .content {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  border-radius: 6px;
}

.front .inner {
  height: 100%;
  display: grid;
  padding: 1.5em;
  transform: translateZ(80px) scale(0.94);
}

.back .inner {
  height: 100%;
  display: grid;
  padding-bottom: 1.5em;
  transform: translateZ(80px) scale(0.94);
}

.front {
  background-color: grey;
  background-size: cover;
  background-position: center center;
}

.front:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 6px;
  backface-visibility: hidden;
}

.front .inner {
  grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
  justify-items: center;
}

.front h2 {
  grid-row: 2;
  margin-bottom: 0.3em;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  font-weight: 500;
  text-shadow: 1px 0 0 rgba(0, 0, 0, 0.719), -1px 0 0 rgba(0, 0, 0, 0.719), 0 1px 0 rgba(0, 0, 0, 0.719), 0 -1px 0 rgba(0, 0, 0, 0.719), 1px 1px rgba(0, 0, 0, 0.719), -1px -1px 0 rgba(0, 0, 0, 0.719), 1px -1px 0 rgba(0, 0, 0, 0.719), -1px 1px 0 rgba(0, 0, 0, 0.719);
  border: 1px rgb(14, 14, 14);
}

.front .rating {
  grid-row: 3;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
}

.front .rating i {
  margin: 0 1px;
}

.back {
  transform: rotateY(180deg);
  background-color: rgb(14, 14, 14);
  border: 2px solid rgb(0, 0, 0);
}

.back .flipcardheader {
  width: 316px;
  height: 190px;
  background-image: linear-gradient(to bottom, rgb(19, 19, 19), rgba(24, 24, 24, 0) 99%);
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}


.back .inner {
  grid-template-rows: 1fr 2fr 1fr 2fr 90fr 1fr 1fr;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 0.8em;
  justify-items: center;
}

.back .info {
  width: 100%;
  height: 54px;
  font-size: 1.3em;
  font-weight: 700;
  padding: 1px;
  text-align: center;
  position: relative;
  align-items: center;
  color: #ffffff;
  text-shadow: 1px 0 0 rgb(0, 0, 0), 1px 0 0 rgb(0, 0, 0), 0 1px 0 rgb(0, 0, 0), 0 1px 0 rgb(0, 0, 0), 0px 0px rgb(0, 0, 0), 0px 1px 0 rgb(0, 0, 0), 0px -1px 0 rgb(0, 0, 0), -1px 0px 0 rgb(0, 0, 0);
  
}

.back .name {
  width: 45%;
  height: 46px;
  float: left;
  padding: 3px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  font-size: 1.1em;
  text-shadow: 1px 0 0 rgb(0, 0, 0), 1px 0 0 rgb(0, 0, 0), 0 1px 0 rgb(0, 0, 0), 0 1px 0 rgb(0, 0, 0), 0px 0px rgb(0, 0, 0), 0px 1px 0 rgb(0, 0, 0), 0px -1px 0 rgb(0, 0, 0), -1px 0px 0 rgb(0, 0, 0);
}

.back .date {
  width: 55%;
  height: 46%;
  float: right;
  padding: 2px;
  margin-left: -5px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  font-size: 0.86em;
  text-shadow: 1px 0 0 rgb(0, 0, 0), 1px 0 0 rgb(0, 0, 0), 0 1px 0 rgb(0, 0, 0), 0 1px 0 rgb(0, 0, 0), 0px 0px rgb(0, 0, 0), 0px 1px 0 rgb(0, 0, 0), 0px -1px 0 rgb(0, 0, 0), -1px 0px 0 rgb(0, 0, 0);
}

.back .button {
  justify-self: center;
}

.button {
  grid-row: -1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  padding: 0 1.5em;
  height: 3em;
  line-height: 2.9em;
  min-width: 3em;
  background-color: rgba(0, 0, 0, 0.699);
  border: solid 2px rgb(0, 0, 0);
  color: #fff;
  border-radius: 4px;
  text-align: center;
  left: 50%;
  backface-visibility: hidden;
  transition: 0.3s ease-in-out;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.932);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  text-shadow: none;
  color: #ffffff;
}

.button.return {
  line-height: 3em;
  cursor: pointer;
  display: block;
  background-color: rgba(0, 0, 0, 0.699);
  color: #ffffff;
  border-color: #000000;
  text-shadow: none;
}

.button.return:hover {
  background-color: rgba(0, 0, 0, 0.932);
  color: #fff;
  box-shadow: none;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

footer {
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  padding: 1px;
  background-color: rgb(8, 8, 8);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #14412a;
  margin-top: 60px;
}

hr {
  height: 1px;
  width: 500px;
  background-color: #b94242;
  border: none;
  margin-top: 50px;
}

img.gglogo {
  padding: 10px;
  height: 100px;
  width: 100px;
}

</style>
