<template>
  <div class="flipcardbox">
    <Flipcard v-for="(item, index) in highouItems" :key="index" :highouItem=item />
  </div>
</template>

<script>
import Flipcard from './Flipcard.vue';
import axios from 'axios';

export default {
  name: 'Dashboard',
  components: {
    Flipcard,
  },
  props: {
    item: Object,
   },
   data () {
    return {
      highouItems: null,
      len: 16,
      loaded: false
    }
   },
  created() {
      axios
        .get('https://www.highou.com/json/recent.php?l='+this.len)
        .then((res) => {
          this.highouItems = res.data;
          this.loaded = true;
        })
        .catch((error) => console.error(error));
  },
  mounted() {
      setInterval(() => {
          let randomID = this.highouItems[Math.floor(Math.random() * this.len)].ID        
          let elementSelected = document.querySelector('#labelButton'+randomID)
          let transformElement = elementSelected.closest(".card");
          transformElement.querySelector(".more").click();
      },3300)
    setInterval(() => {

      axios
        .get('https://www.highou.com/json/recent.php?l='+this.len)
        .then((res) => {
          this.highouItems = res.data;
        })
        .catch((error) => console.error(error));
    } , 30000);
  }

  
};
</script>
