  <template>
  <div class="flipcard">
       <div class="wrapper">
        <div class="card">
            <input type="checkbox" id="card" class="more">
            <div class="content" :id="'content' + highouItem.ID">
                <div class="front" :style="{ backgroundImage: 'url(https://highou.com/' + highouItem.Foto + ')' }">
                    <div class="inner">
                        <h2>{{highouItem.Content}}</h2>
                        <label for="card" class="button frontButton" aria-hidden="true" :id='"labelButton" + highouItem.ID + ""'>
                            Kaart
                        </label>
                    </div>
                </div>
                <div class="back">
                    <div class="inner" :style="{ backgroundImage: 'url(https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyA9LemZdEdoraotsKIPI6cMGJk4v1dURHQ&size=320x400&markers=' + highouItem.Latitude + ',' + highouItem.Longitude + '&mobile=true&zoom=12' }" >
                        <div class="flipcardheader">
                        <div class="info">{{highouItem.Content}}</div>
                        <div class="name">{{highouItem.Name}}</div>
                        <div class="date">{{highouItem.date}}</div>
                        </div>
                        <label for="card" class="button return" aria-hidden="true">
                          <font-awesome-icon icon="arrow-left" />

                            <!-- <i class="fas fa-arrow-left"></i> 
                                https://www.highou.com/blogImages/2/619a409a41069-1637499032044.jpg-->
                        </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
</template>

<script>
export default {
  name: 'Flipcard',
  props: {
    highouItem: Object
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin:  10px;
}
a {
  color: #b94242;
}
</style>
